*
:after
:before
  box-sizing: border-box;


dl, ol, ul
  margin-top: 0;


hr
  border: 0;
  border-top: 1px solid rgba(0,0,0,.1);


h1, h2, h3, h4, h5, h6
  margin: 0;


p
  margin-top: 0;
  margin-bottom: 1rem;
