label
  &.required-marker
    :after
      content: "*";
      color: #f05300;
input
  width: 100%;
  height: 40px;
  padding: 12px 16px;
  border-radius: 8px;
  border: solid 1px var(--input-border-color);
  background-color: var(--input-background);
  outline: none;
  &:focus
    border-color: var(--input-focus-border-color);
  &[disabled]
  &[readonly]
    background-color: var(--input-disabled-background)!important;
    color: var(--input-disabled-color);
  &[type=checkbox]
    position: relative;
    width: 18px;
    height: 18px;
    margin 3px
    flex: 0 0 18px;
    &:indeterminate
      &::before
        content: "";
        display: block;
        color: white;
        width: 18px;
        height: 18px;
        border-radius: 2px;
        background-color:#0075ff;
      &::after
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        border: solid white;
        border-width: 2px 0 0 0;
        position: absolute;
        top: 8px;
        left: 4px;
  &[type=radio]
    width: 16px;
    height: 16px;
  &.invalid
    border-color: var(--error-text-color);
  &::placeholder
    font-family: Roboto, Helvetica Neue, sans-serif;
    font-size: 14px;
    color vat(--grey-text-color)


.ui-kit-tag-list
  display: flex;
  flex-wrap: wrap;
  gap: 8px
  .ui-kit-tag
    border-radius: 8px;
    width: fit-content;
    background-color: #eeeeee;
    padding: 8px;
    outline: none;
    cursor: pointer;
    &.tag__selected
      background-color: #0095da;
      font-size: 14px;
      color: #ffffff;
      outline: none;
      cursor: pointer;
    &__counter
      margin-left: 4px;
      padding: 4px;
      border-radius: 8px;
      color: #999999;
      background-color: #f7f8fa;


.checkbox
  a
    color: var(--link-color);


.radio
  position: relative;
  padding: 0 0 4px 28px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  input
    position: absolute;
    opacity: 0;
    cursor: pointer;
  .checkmark
    position: absolute;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #ccc;
    &:after
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
  &:hover input ~ .checkmark
    background-color: #ccc;
  input:checked ~ .checkmark
    background-color: #2196F3;
    border: none;
  input:checked ~ .checkmark:after
    display: block;



/* form fields layout */
.columns-field_1
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  label
    display inline-block
.columns-field_2
  display grid
  grid-template-columns 150px auto
  column-gap 24px
  row-gap 8px
  align-items center
  input
    width: 100%;
    max-width 100%
  .column-2
    grid-column-start 2
    grid-column-end 3
    width fit-content
  .right-side
    grid-column-start: 2;
    grid-column-end: 3;
  .field-description
    position relative
    padding-left 16px
    .description__right-side
      float right
