// material tooltip
.mat-tooltip-panel-below
  mat-tooltip-component:before
    display: block
.mat-tooltip-panel-above
  mat-tooltip-component:after
    display: block

@mixin arrow
  display: none
  position: absolute
  content: ''
  width: 0
  height: 0
  right: 9px
  border-left: 4px solid transparent
  border-right: 4px solid transparent


mat-tooltip-component
  margin: 0 0 0 -45%
  position: relative
  &:before
    @include arrow
    top: -4px
    border-bottom: 4px solid #333
  &:after
    @include arrow
    bottom: -4px
    border-top: 4px solid #333
  .mat-tooltip
    margin: 0
    padding: 16px!important
    background: #333
    font-size: 14px!important
    line-height: 16px
    border-radius: 8px!important



// custom tooltip
.tooltips
  position: relative
  .tooltips-text
    display: none
    visibility: hidden
    max-width: 320px
    background-color: #ef3e58
    color: #ffffff
    border-radius: 8px
    padding: 16px
    position: absolute
    z-index: 1
    bottom: 110%
    right: 0
    opacity: 0
    transition: .3s
    transform: translate3d(0px, 20px, 0px)
  &:hover
    .tooltips-text
      display: block
      visibility: visible
      opacity: 1
      transform: translate3d(0px, 0px, 0px)

// ng-zorro tooltip
.ant-tooltip-inner
  border-radius: 8px
  padding: 8px
  background-color: #333
  line-height: 16px
  width: max-content
