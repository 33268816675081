@use 'assets/styles/buttons'
@use 'assets/styles/common-blocks'
@use 'assets/styles/form'
@use 'assets/styles/modal'
@use 'assets/styles/reset'
@use 'assets/styles/tooltip'

@font-face
  font-family: Roboto
  src: url("assets/fonts/Roboto-Regular.ttf") format("opentype")



.mat-menu-panel
  min-width: fit-content !important
  background: #fff



\:root
  --text-color: #333
  --text-secondary-color: #ccc
  --grey-text-color: #999
  --text-success-color: #13ce66
  --text-error-color: #ef3e58

  --button-main-color: #fff
  --button-main-background: #0095da
  --button-main-hover-background: #08A9F3
  --button-disabled-color: #999
  --button-disabled-background: #eee

  --input-background: #fff
  --input-border-color: #ddd
  --input-disabled-color: #333
  --input-disabled-background: #eee
  --input-focus-border-color: #0095da
  --input-error-border-color: #ef3e58

  --link-color: #0095da
  --link-hover-color: #08a9f3

  --border: 1px solid #eee
  --border-color: #eee

  --icon-main-color: #333
  --icon-secondary-color: #ccc

  --border-radius: 8px
  --narrow-gap: 8px
  --gap: 16px
  --form--elements-gap: 24px
  --margin: 24px
  --margin-sm: 8px
  --margin-md: 16px
  --margin-lg: 24px
  --block-side-padding: 16px


  --white-bg-color: #ffffff
  --black-bg-color: #333333
  --grey-bg-color: #f7f8fa
  --grey-bg-color-hover: #f0f2f8
  --grey-button-hover: #EEEEEE
  --grey-button-active: #DDDDDD
  --blue-text-color: #0095da
  --blue-text-color-focused: #08a9f3
  --navigation-hover: #CCCCCC
  --navigation-active: #F05300

  --transition-time: .3s



html,
body
  height: 100%


body
  margin: 0
  font-family: Roboto, "Helvetica Neue", sans-serif
  font-size: 14px
  line-height: 16px
  -webkit-text-size-adjust: 100%
  -webkit-tap-highlight-color: transparent
  background-color: var(--grey-bg-color)
  color: #333


main
  height: 100%
  width: 100%



router-outlet + *
  display: flex
  flex-direction: column
  gap: 16px


a
  text-decoration: none
  color: var(--link-color)
  cursor: pointer
  &:hover
    color: var(--link-hover-color)
  &.like-button
    font-size: 16px
.ui-kit-like-link
  cursor: pointer
  color: var(--blue-text-color)
  &:hover
    color: var(--blue-text-color-focused)


.ui-kit-bold-text
  font-weight: 700

.ui-kit-hidden,
.hidden
  display: none!important

.ui-kit-transparent
  opacity: 0

.tax-main
  margin-left: 200px
  width: 800px
  .tax-alert
    margin: 16px 24px
  .page-content
    padding: 32px 0


.ui-kit-grey-text,
.grey-text
  color: var(--grey-text-color)


.ui-kit-green-text,
.green-text
  color: var(--text-success-color)


.ui-kit-error-text,
.error-text
  color: var(--text-error-color)


.clickable:hover
  cursor: pointer
  text-decoration: underline


.currency::after
  content: ' ₸'
  font-family: Arial, sans-serif


svg-icon
  display: flex
  &.grey-icon
    color: #ccc
  &.orange-icon
    color: #F05300
  &.green-icon
    color: var(--text-success-color)
  svg
    display: block

.ui-kit-align-right
  margin-left: auto

.ui-kit-centering-string,
.centering-string,
.centering-string.mat-menu-item
  display: flex
  align-items: center
.ui-kit-centering-column
  display: flex
  align-items: center
  flex-direction: column


.ui-kit-break-word
  word-break: break-word
.ui-kit-break-all
  word-break: break-all


.text_field
  height: 40px
  border-radius: 8px
  border: solid 1px #dddddd
  background-color: #ffffff !important
  outline: none
  padding: 12px 16px


.text_field:focus
  border: solid 1px #08a9f3


.greyline
  margin: 24px 0
  border: 0
  border-top: 1px solid #eeeeee



.modal_body
  margin: 8px 24px 56px 24px
  padding: 8px 0
  border-radius: 8px
  background-color: #ffffff
  font-size: 14px
  font-style: normal
  font-stretch: normal
  font-weight: normal
  line-height: 1.14
  overflow: hidden
  overflow-y: auto
  height: 60%


.group_modal_title
  display: flex
  flex-direction: row
  font-size: 16px
  font-weight: bold
  line-height: 1.5
  letter-spacing: normal
  color: #333333


.group_modal_title button
  background-color: #f7f8fa
  align-items: flex-end
  margin-left: auto
  padding: 0 !important


.select_params
  margin-top: 16px
  font-size: 14px
  background-color: #ffffff
  color: #0095da
  padding: 0


.select_params:hover
  color: #08A9F3
  cursor: pointer


.ui-kit-block-title,
.block_title
  font-size: 16px
  font-weight: bold
  line-height: 1.5


.setting_description
  margin: 16px 0 8px
  font-size: 14px
  line-height: 1.14
  word-break: break-all



.ui-kit-dot-separated-item:not(:last-child)
  &:after
    content: ""
    display: inline-block
    margin: 0 5px 2px 4px
    background: #333
    width: 6px
    height: 6px
    border-radius: 100%


@mixin outer-border-top
  &:before
    content: ''
    display: block
    margin: 0 calc(var(--gap) * -1) var(--margin)
    border-top: var(--border)
    width: calc(100% + var(--gap) * 2)


@mixin ui-kit-rows-layout
  display: grid
  gap: 16px
@mixin ui-kit-rows-layout--bordered
  @include ui-kit-rows-layout
  &:before
    content: ''
    margin: 0 calc(var(--gap) * -1) calc(var(--margin) - var(--gap))
    border-top: var(--border)
    width: calc(100% + var(--gap) * 2)
.ui-kit-rows-layout
  @include ui-kit-rows-layout
  &--bordered
    @include ui-kit-rows-layout--bordered



@mixin ui-kit-layout-columns-2
  display: grid
  grid-template-columns: 150px calc(100% - 150px - var(--form--elements-gap))
  grid-column-gap: var(--form--elements-gap)
  row-gap: 8px
  &__centering,
  label
    align-self: center
  .ui-kit-align-end
    align-self: end
  .column-2
    grid-column-start: 2
    grid-column-end: 3
    width: fit-content

.ui-kit-layout-columns-2
  @include ui-kit-layout-columns-2


.ui-kit-ellipsis-text
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis


.user__parameter
  display: grid
  grid-template-columns: 150px calc(100% - 150px - var(--form--elements-gap))
  grid-column-gap: 24px
  align-items: center
  margin-bottom: 24px


.user__parameter label
  margin: 0



.required-marker:after
  content: "*"
  color: #f05300


.colon:after
  content: ':'


.tall-text
  font-size: 16px


.bold-text
  font-size: 16px
  line-height: 1.5
  font-weight: bold


.not_found_block
  text-align: center
  border-radius: 8px
  background-color: var(--white-bg-color)
  padding: 28px 0


.mat-tooltip
  font-size: 12px


.cdk-global-scrollblock
  overflow-y: hidden

.cdk-global-overlay-wrapper
  overflow: auto
  pointer-events: auto



