$close-modal-icon: "data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><g fill='none' fill-rule='evenodd'><path fill='%23333' d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></g></svg>"
$arrow-left-icon: "data:image/svg+xml; utf8, <svg width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g fill='none' fill-rule='evenodd'><path d='M0 0h24v24H0z'/><path fill='currentColor' d='M16 5.43 14.635 4 7 12l7.635 8L16 18.57 9.73 12z'/></g></svg>"
$more-icon: "data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><g fill='none' fill-rule='evenodd'><path fill='%23000' d='M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z'/></g></svg>"

button
  height: fit-content
  font-family: Roboto,Helvetica Neue,sans-serif
  font-size: 14px
  border: none
  outline: none !important
  background-color: #f7f8fa
  cursor: pointer
  &[disabled]
    background-color: var(--button-disabled-background)
    color: var(--button-disabled-color)
    cursor: default


.button_base
  border-radius: 8px
  font-size: 16px
  line-height: 1.5
  padding: 8px 32px
  color: var(--blue-text-color)
  margin-right: 24px
  &:focus
    color: var(--blue-text-color-focused)
    background-color: var(--grey-button-active)
  &:hover
    color: var(--blue-text-color-focused)
    background-color: var(--grey-button-hover)


@mixin ui-kit-button
  border-radius: 8px
  font-size: 16px
  line-height: 1.5
  padding: 8px 32px
  max-width: fit-content
  white-space: nowrap
.ui-kit-button
  @include ui-kit-button
  &[disabled]
    pointer-events: none
.ui-kit-button_blue,
.button_blue
  @include ui-kit-button
  background-color: var(--button-main-background)
  color: var(--button-main-color)
  &[disabled]
    background-color: var(--button-disabled-background)
    color: var(--button-disabled-color)
  &:hover:not([disabled])
    background-color: var(--button-main-hover-background)
.ui-kit-button_gray
  @include ui-kit-button
  background-color: var(--grey-bg-color-hover)
  color: var(--button-main-background)


.ui-kit-button_link,
.button_link
  width: fit-content
  color: var(--link-color)
  background-color: transparent
  padding: 0
  font-size: 14px
  line-height: 16px
  cursor: pointer
  &:hover
    color: var(--blue-text-color-focused)
  &[disabled]
    color: #999
    background-color: transparent
    &:hover
      color: #999


.close-button
  display: block
  width: 24px
  height: 24px
  background-image: url($close-modal-icon)
.back-button
  display: block
  min-width: 24px
  height: 24px
  padding-left: 32px
  font-size: 16px
  line-height: 22px
  content: ''
  background: transparent url($arrow-left-icon) 0 50% no-repeat
  filter: invert(42%) sepia(77%) saturate(4792%) hue-rotate(177deg) brightness(104%) contrast(101%)
  &:hover
    filter: invert(48%) sepia(80%) saturate(1216%) hue-rotate(166deg) brightness(97%) contrast(97%)
    color: #08a9f3
  &[disabled]
    background-color: transparent
    filter: invert(70%) sepia(0%) saturate(0%) hue-rotate(109deg) brightness(88%) contrast(84%)
.more-button
  display: block
  width: 30px
  height: 24px
  background: url($more-icon) 50% 50%
