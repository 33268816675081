.ui-kit-bottom-buttons-block
  padding: 32px 0 0!important
  display: flex
  align-items: center
  justify-content: space-between
  button:not(:last-child)
    margin-right: 16px
  .right
    margin-left: auto



.ui-kit-white-card
  background-color: var(--white-bg-color)
  border-radius: 8px
  width: 100%
  .ui-kit-white-card__body
    grid-template-columns: repeat(2, 1fr)
    display: grid
    padding: 24px


@mixin ui-kit-content-container
  background-color: #fff
  border-radius: 8px
  padding: 24px 16px 16px 16px
@mixin ui-kit-content-container--with-separators
  @include ui-kit-content-container
  > *
    position: relative
    padding: 24px 0
    display: flex
    &:first-child
      padding-top: 0
    &:not(:nth-last-child(-n+2)):after
      content: ''
      position: absolute
      bottom: 0
      margin: 0 -16px
      border-top: var(--border)
      width: calc(100% + 16px * 2)
.ui-kit-content-container
  @include ui-kit-content-container
  &--with-separators
    @include ui-kit-content-container--with-separators


.ui-kit-blocks-list--with-separator
  &>:nth-child(n):not(:first-child)
    margin: var(--margin-lg) calc(var(--block-side-padding) * -1) var(--margin-lg)
    padding: var(--margin-lg) var(--block-side-padding) 0
    border-top: var(--border)
    width: calc(100% + var(--block-side-padding) * 2)
    &:last-child
      margin-bottom: 0



@mixin ui-kit-item-list-container
  display: grid
  row-gap: 8px
.ui-kit-item-list-container
  @include ui-kit-item-list-container
@mixin ui-kit-item-container
  display: flex
  align-items: center
  column-gap: 8px
  border-radius: 8px
  background-color: #ffffff
  padding: 16px
.ui-kit-item-container
  @include ui-kit-item-container


.ui-kit-outer-line
  margin: var(--margin) calc(var(--gap)*-1)


/* Dropdown */
.dropdown-list
  position: relative
  display: inline-block
  margin-left: auto

.dropdown-container
  display: none
  position: absolute
  right: 0
  background-color: #ffffff
  border-radius: 8px
  box-shadow: 0 2px 10px 0 rgba(51, 51, 51, 0.1)
  z-index: 1
  padding: 8px 0
  color: #333
  .dropdown-content
    margin: 8px 16px
    display: flex
    align-items: center
    cursor: pointer
  .dropdown-item
    padding: 8px 24px 8px 16px
    display: flex
    align-items: center
    cursor: pointer
    white-space: nowrap
    .icon,
    svg-icon
      color: var(--icon-secondary-color)
    &:active
      color: #333 // override bootstrap styles
    &:hover
      background-color: var(--grey-bg-color-hover)
      svg-icon
        color: var(--black-bg-color)
  span
    padding-left: 8px

.dropdown-visible
  .dropdown-container
    display: block
  .more-button
    filter: invert(48%) sepia(80%) saturate(1216%) hue-rotate(166deg) brightness(97%) contrast(97%)
