/*
  .loader-body
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    opacity: 0.75;
    z-index: 900;
    position: absolute;
    width: 100%;
    height: 100%;
    .loader-block
      display: flex;
      justify-content: center;
      border-radius: 8px;
      align-items: center;
      background-color: #f7f8fa;
      position: absolute;
      z-index: 2;
      height: 40px;
      width: 120px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

